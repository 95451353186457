* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

body.body-fijo{
  height: 100%;
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
}

.whatsapp-detail {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container-top-header p {
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
}

.container-top-header {
  margin-left: 40px;
}
.container-wsp-phone {
  display: flex;
  align-items: center;
}

.tel-header {
  text-decoration: none;
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.separacion {
  border-left: 3px solid green;
  height: 20px;
  margin: 0 10px;
}

.icon {
  margin: 0 5px;
  font-size: 12px;
}

.container-wsp-phone img {
  width: 14px;
  height: 14px;
}

.logo-principal img {
  max-width: 130px;
}

.container-bottom-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  justify-content: space-between;
}

.nav-bar ul {
  display: flex;
}

.sections{
  list-style: none;
  padding: 5px 20px;
}

/* .sections:hover  {

  border-bottom: 2px solid green;
}
*/
.nav-bar ul li a {
  text-decoration: none;
  color: black;
}

.slider-img {
  width: 100%;
  max-height: 600px;
}

.text {
  font-size: 4vw;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 17vw;
  left: 23vw;
  color: white;
  text-shadow: 0 0 2px #464343;
}

.container-carrousel-slider {
  position: relative;
  width: 100%;
}

.item-detail {
  border: 1px solid red;
}
.description {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 3vh;
}

.container-carrousel {
  display: flex;
  align-items: center;
  width: 60%;
}

.carrousel-img {
  width: 45%;
  transition: 0.5s;
}

.pros-cercos {
  width: 100%;
  

}

.carrousel-img:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.carousel-detail {
  text-align: center;
  margin-top: 30px;
}

.carousel-detail h1 {
  font-size: 20px;
  margin-top: 15px;
  text-align: center;
}

/*
.img-1,
.img-2 {
  width: 80%;
}*/

.what-we-do-container h1 {
  text-align: center;
  margin-top: 80px;
  font-weight: 400;
}

.work-numbers-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 60px;
}

.phrase-increase {
  font-size: 1.2vw;
  color: grey;
  text-align: center;
}

.number {
  font-size: 5vw;
  text-align: center;
  font-weight: bold;
  color: #2fd79f;
}

.galery-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 40px;
  height: 40vw;
}

.galery-container img {
  width: 100%;
  position: absolute;
}

.galery-container img:hover {
  filter: sepia(60%);
}

.how-we-work-container {
  width: 20vw;
  text-align: center;
  position: relative;
  top: 18vw;
  left: 5vw;
}

.how-we-work-container p {
  font-size: 2vw;
  color: white;

  margin-bottom: 1vw;
  text-align: center;
}

.rutes-styles {
  padding: 10px 20px;
  background-color: #2fd79f;
  color: black;
  text-transform: uppercase;
  font-size: 1.2vw;
  font-weight: 500;
  text-decoration: none;
}

.underlined {
  margin: 30px 0;
  width: 5vw;
  margin-left: 37%;
  border: 2px solid #2fd79f;
}

.galery-detail {
  position: relative;
  height: 100%;
  margin-bottom: 25px;
}

.brand img {
  width: 15vw;
}

.separacion-dos {
  border-bottom: 3px solid green;
  height: 20px;
  margin: 0 10px;
  width: 15px;
  margin-left: 10vw;
}

.container-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.design-page{
  display: flex;
  align-items: center;
}

.design-page p{
  font-size: 16px;
}

.logo-hg{
  width:40px;
}

.container-logo-mail {
  display: flex;
  margin-right: 8vw;
}

.separacion-icon-mail {
  display: flex;
  align-items: center;
  margin-right: 10vw;
}

.linea-divisoria-footer {
  width: 100%;
  border-bottom: 1px solid rgb(226, 220, 220);
  margin-bottom: 20px;
}

.logo-footer  {
  width: 130px;
}

/*HEADER COMPLEMENTARIO*/

.header-two-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.complementario {
  border-bottom: 3px solid green;
  height: 20px;
  width: 15px;
}
.logo-complementario {
  margin-right: 8vw;
  margin-top: 30px;
  width: 200px;
}

.logo-close {
  width: 20px;
  margin-top: 30px;
 
}

.details-header-complementario {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*CONTACTO*/

.container-contacto {
  background-color: rgb(241, 241, 241);
  margin: 20px 0 0 20px;
  padding: 10px;
  max-width: 100%;
}

.form-container {
  border: 1px solid white;
  background-color: white;
  box-shadow: 1px 1px 2px 2px rgba(231, 228, 228, 0.456);
  padding: 30px;
  border-radius: 5px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  border: 1px solid rgba(232, 227, 227, 0.552);
  padding: 8px 0;
}

.form-container textarea {
  resize: none;
}

.form-container label {
  display: block;
  margin-top: 12px;
  margin-bottom: 4px;
}

.container-detail {
  display: grid;
  grid-template-columns: 2.5fr 2.5fr;
  gap: 50px;
  flex-wrap: wrap;
  position: relative;
}

.container-img-contact img {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  position: absolute;
  left: 80px;
  top: 10px;
}

.container-img-contact {
  max-width: 100%;
  position: relative;
}

.presupuesto-detail {
  padding: 15px 0;
  font-weight: 300;
  font-size: 40px;
}

.form-button {
  padding: 15px 35px;
  border: 1px solid greenyellow;
  background-color: greenyellow;
  margin: 30px 0;
}

.recaptcha {
  margin-top: 20px;
}

/* NOSOTROS */

.container-nosotros {
  background-color: rgb(241, 241, 241);
  margin: 20px 0 0 20px;
  display: flex;
  padding-bottom: 15px;
}
.container-nosotros img {
  height: 52vw;
  width: 60vw;
  margin-top: 55px;
  margin-left: 5vw;
}

.galeria-text {
  width: 100%;
  margin-left: 20px;
}

.galeria-text h1 {
  font-size: 40px;
  margin-left: 15px;
  font-weight: 300;
}

.galeria-text p {
  font-size: 19px;
  margin: 15px;
}

.nosotros-button {
  border: 1px solid black;
  width: 300px;
  margin-left: 14px;
  font-size: 22px;
  border-radius: 5px;
  background-color: black;
  padding: 8px;
  margin-top: 20px;
  text-align: center;
}

.container-como-trabajamos {
  background-color: rgb(241, 241, 241);
  margin: 20px 0 0 20px;
  padding-bottom: 15px;
}
.detail-button {
  text-decoration: none;
  color: white;
}

.container-como-trabajamos p {
  line-height: 22px;
}

.como-trabajamos-detail-left {
  margin-right: 50px;
  margin-left: 30px;
}

.como-trabajamos-detail-left p {
  margin-bottom: 20px;
}

.como-trabajamos-detail-left ul {
  margin-top: 20px;
}

.como-trabajamos-detail-left ul li {
  list-style: none;
  font-weight: bold;
}

.como-trabajamos-detail-left h1 {
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 300;
}

.container-como-trabajamos {
  display: flex;
  height: 100%;
}

.detail-rigth-up {
  margin-bottom: 20px;
  margin-right: 30px;
}

.container-como-trabajamos h2 {
  margin: 15px 0 20px 0;
  font-size: 18px;
}

.logo-nice {
  width: 140px;
  height: 140px;
  border: 1px solid #255297;
  background-color: #255297;
  position: relative;
}

.logo-nice span {
  color: white;
  font-size: 40px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-weight: bold;
}

.detail-rigth-up {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-rigth-text {
  max-width: 40vw;
}

.trabajo-img img {
  width: 60vw;
  height: 30vw;
}

/* VIDEOSEGURIDAD */

.videoseguridad-img img {
  width: 55vw;
  height: 20vw;
}
.container-seguridad {
  background-color: rgb(241, 241, 241);
  margin: 20px 0 0 20px;
  padding-bottom: 15px;
}

.container-como-trabajamos p {
  line-height: 22px;
}

.seguridad-detail-left {
  margin-right: 50px;
  margin-left: 30px;
}

.seguridad-button {
  padding: 10px 20px;
  border: none;
  color: white;
  background-color: rgb(12, 189, 110);
  font-size: 18px;
  margin-top: 50px;
  cursor: pointer;

  

}

.seguridad-button a {
  text-decoration: none;
  color: white;
}

.seguridad-detail-left p {
  margin-bottom: 20px;
}

.seguridad-detail-left {
  width: 100%;
}

.seguridad-general {
  display: flex;
  justify-content: space-around;
}

.seguridad-group {
  width: 30vw;
}

.seguridad-group p {
  margin-bottom: 15px;
}

.container-seguridad h1 {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 15px;
  font-size: 45px;
  font-weight: 300;
  display: inline-block;
}

.container-seguridad h2 {
  margin-top: 15px;
  font-size: 18px;
}

/*GALERIA*/

.galeria-detail {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 3px;
}

.works {
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 40px;
}

.galeria-detail button {
  border: none;
  cursor: pointer;
}

.galeria-container img {
  width: 100%;
  height: 100%;
}

.galeria-container {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.galeria-texto {
  text-align: center;
  position: absolute;
  color: white;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 50px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.hamburguer-menu span {
  padding: 2px 16px;
  color: black;
  display: block;
  background: black;
  margin: 5px 0;
}
.hamburguer-menu {
  border: transparent;
  background: transparent;
  margin-right: 10px;
  display: none;
  cursor: pointer;
}
.nav-bar-open {
  width: 100%;
  height: 200vh;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #fff, rgb(208, 208, 208));
  background-size: cover;
  opacity: 0.9;
  padding: 50px;
}

.nav-bar-open ul {
  display: block;
}

.nav-bar-open li {
  font-size: 20px;
  margin: 10px 0;
}

.close-nav {
  cursor: pointer;
  border: none;
  color: black;
  font-size: 26px;
  background-color: transparent;
  display: none;
  padding: 20px;
}



/*RESPONSIVE*/

@media screen and (max-width: 1250px) {
  .logo-nice {
    display: none;
  }

  .detail-rigth-text {
    max-width: 60vw;
  }

  .como-trabajamos-detail-left h1 {
    font-size: 35px;
  }

  .trabajo-img img {
    height: 45vw;
  }
}

@media screen and (max-width: 1100px) {
  .container-detail {
    grid-template-columns: 2.5fr;
  }

  .container-img-contact {
    display: none;
  }

  .container-contacto {
    margin: 20px;
  }
  .container-nosotros {
    display: block;
  }

  .container-nosotros img {
    width: 85vw;
    margin-top: 55px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 10px;
    margin: 15px;
  }

  .galeria-text {
    margin-left: 0;
  }

  .nosotros-button {
    width: 200px;
    font-size: 18px;
  }
  .galeria-background {
    margin: 20px 0 20px 0;
  }


}

@media screen and (max-width: 1000px) {
  .container-como-trabajamos {
    display: block;
    margin: 0 30px;
  }

  .como-trabajamos-detail-left {
    margin: 0;
  }
  .como-trabajamos-detail-left h1 {
    font-size: 25px;
  }

  .trabajo-img img {
    width: 100%;
    height: 40vw;
  }

  .galeria-detail {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .galeria-background {
    margin: 20px 0 20px 0;
  }

  .container-carrousel {
    display: none;
  }

  /*   HAMBURGUER MENU  */

  .hamburguer-menu {
    display: block;
  }

  .nav-bar {
    display: none;
  }

  .nav-bar-open {
    display: block;
  }

  .close-nav {
    display: block;
  }

  .seguridad-general {
    display: block;
  }

  .container-seguridad {
    margin: 20px;
  }

  .seguridad-group {
    width: 70vw;
  }

  .seguridad-detail-rigth {
    margin-left: 30px;
  }

  .videoseguridad-img img {
    width: 96%;
    height: 40vw;
  }
}

@media screen and (max-width: 800px) {
  .galeria-detail {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .galeria-detail img {
    width: 100%;
  }

  .description {
    font-size: 18px;
  }

  .number {
    font-size: 40px;
    padding: 20px 0;
  }
  .phrase-increase {
    font-size: 13px;
  }

  .work-numbers-container {
    display: block;

    margin-top: 10px;
  }

  .design-page p {

    font-size: 12px;

  }

  /*GALERIA RESPONSIVE*/

  .galery-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100%;
  }

  .how-we-work-container {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
  }

  .how-we-work-container p {
    font-size: 30px;
    color: white;
    margin-bottom: 1vw;
    text-align: center;
  }

  .rutes-styles {
    padding: 10px 20px;
 
    color: black;
    text-transform: uppercase;
    font-size: 3vw;
    font-weight: 500;
    text-decoration: none;
  }

  .underlined {

    text-align: center;
    width: 24vw;
    margin-bottom: 40px;
  }

  .galery-detail {
    position: relative;
  }

  .galery-container img {
    position: static;
  }

  .separacion-dos {
    display: none;
  }

  .container-footer {

    flex-direction: column-reverse;
  }

  .container-logo-mail {
    display: block;
    text-align: center;
    margin-right: 0;
  }

  .separacion-icon-mail {
    justify-content: center;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .logo-footer img {
    width: 160px;
  }

  .design-page{
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 630px) {
  .description {
    font-size: 15px;
  }

  .brand img {
    width: 22vw;
  }

  .container-logo-mail {
    display: block;
    text-align: center;
  }

  .separacion-icon-mail {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .galeria-detail {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .galeria-background {
    margin: 20px 0 20px 0;
  }
  .brand img {
    width: 30vw;
  }

  .videoseguridad-img img {
    height: 80vw;
  }

  .seguridad-button {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .slider-img {
    width: 100%;
    height: 400px;
  }

  .text {
    font-size: 5vw;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 44vw;
    left: 16vw;
    color: white;
    text-shadow: 0 0 2px #464343;
  }
  .whatsapp-detail {
    display: block;
  }

  .wsp-logo {
    padding: 10px;
    text-align: center;
  }

  .horario {
    text-align: center;
  }

  .icon {
    font-size: 15px;
  }

  .icon .whatsapp-detail img {
    text-align: center;
  }
  .horario {
    text-align: center;
  }

  .separacion {
    display: none;
  }
  .container-wsp-phone {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .whatsapp-detail {
    text-align: center;
  }

 .container-top-header{
  margin-left: 0;
  
 }

 .tel-header{
  font-size: 16px;
 }

 .recaptcha {
  transform: scale(0.75);
  transform-origin: 0% 50%;
 }


}
